import { NavItemData } from '.';

export const mainNavData: NavItemData[] = [
  {
    text: 'Solutions',
    to: '/solutions/',
    subnav: [
      {
        text: 'Overview',
        to: '/solutions/',
      },
      {
        text: 'Rebate and Loyalty Programs',
        to: '/rebate-and-loyalty-programs/',
      },
      {
        text: 'Incentive and Reward Programs',
        to: '/incentive-and-reward-programs/',
      },
      {
        text: 'Co-op Advertising/MDF',
        to: '/coop-advertising-mdf/',
      },
      {
        text: 'Brand Compliance',
        to: '/brand-compliance/',
      },
    ],
  },
  {
    text: (
      <>
        Channel Marketing
        <br tw="block lg:hidden" /> Technology
      </>
    ),

    to: '/channel-marketing-technology/',
  },
  {
    text: (
      <>
        Industries <br tw="block lg:hidden" />
        Served
      </>
    ),
    to: '/automotive/',
    subnav: [
      { text: 'Automotive', to: '/automotive/' },
      { text: 'Consumer Durables', to: '/consumer-durables/' },
      { text: 'Manufacturing', to: '/manufacturing/' },
      { text: 'Fortune 1000', to: '/fortune-one-thousand/' },
    ],
  },
  {
    text: 'Resources',
    to: '/resources/',
  },
  {
    text: 'About',
    textMobile: 'About ACB',
    to: '/about-acb/',
    subnav: [
      {
        text: 'History',
        to: '/about-acb/',
      },
      {
        text: 'Careers',
        to: '/acb-careers/',
      },
    ],
  },
];

export const topNavCtaData: NavItemData = {
  text: 'Request a demo',
  to: '/request-a-demo/',
};

export const footerNavData = [
  ...mainNavData,
  {
    text: 'Contact Us',
    to: '/request-a-demo/#contact-us',
  },
];

export const videoCtaData: NavItemData = {
  text: 'Request a Demo Today',
  to: '/request-a-demo/',
};
